// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SelectionPage from './pages/selectionPage';
import LoginPage from './pages/loginPage';
import SubmissionPage from './pages/submissionPage';
import ConfirmationPage from './pages/confirmPage';
import Status from './pages/statusPage';
import './App.css';
import ChatPage from './pages/chatPage';
import AgreementPage from './pages/agreementPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SelectionPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/submission" element={<SubmissionPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/chat" element={<ChatPage/>}/>
        <Route path="/status" element={<Status />} />
        <Route path="/agreement" element={<AgreementPage/>} />
      </Routes>
    </Router>
  );
}

export default App;