import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Container, Paper, Stack } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import logo from '../assets/timeload.png';
import { useLocation, useNavigate } from 'react-router-dom';
import baseUrl from '../config';
import CancelPopup from '../components/status/cancelPopup';
import AddTracking from '../components/status/addTracking';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Status() {
  const [key, setKey] = useState(0);
  const [requests, setRequests] = useState([]);
  const [receivedNumbers, setReceivedNumbers] = useState({});
  const [isAddTrackingOpen, setAddTrackingOpen] = useState(false);
  const [isCancelPopupOpen, setCancelPopupOpen] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedPWD, setSelectedPWD] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();


  const handleOpenAddTracking = (requestId, pwd) => {
    setSelectedRequestId(requestId);
    setSelectedPWD(pwd);
    setAddTrackingOpen(true);
  };

  const handleOpenCancelPopup = (requestId, pwd) => {
    setSelectedRequestId(requestId);
    setSelectedPWD(pwd);
    setCancelPopupOpen(true);
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleOpenChat = (requestId, pwd) => {
    navigate('/chat', { state: { requestInfo: { requestId: requestId, pwd: pwd } } }); // Pass the requests data to /status route
  };

  const handleCloseAddTracking = () => {
    setSelectedRequestId(null);
    setSelectedPWD(null);
    setAddTrackingOpen(false);
  };

  const handleCloseCancelPopup = () => {
    setSelectedRequestId(null);
    setSelectedPWD(null);
    setCancelPopupOpen(false);
  };

  const updateRequest = (requestId, newSentCompany, newSentNum) => {
    const updatedRequests = requests.map(request => {
      if (request.requestId === requestId) {
        return { ...request, sentCompany: newSentCompany, sentNum: newSentNum };
      }
      return request;
    });
    setRequests(updatedRequests);
    console.log(`Request ${requestId} updated with new company: ${newSentCompany} and number: ${newSentNum}`);
  };

  const handleConfirmAddTracking = async (sentCompany, sentNum) => {
    try {
      const response = await fetch(baseUrl + '/api/status/addTrackingNumber', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestId: selectedRequestId, pwd: selectedPWD, sentCompany: sentCompany, sentNum: sentNum }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const result = await response.json();
      if (result.success) {
        updateRequest(selectedRequestId, sentCompany, sentNum);
        setKey(prevKey => prevKey + 1);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
    setSelectedRequestId(null);
    setSelectedPWD(null);
    setCancelPopupOpen(false);
  };

  const deleteRequest = (requestId) => {
    const updatedRequests = requests.filter(request => request.requestId !== requestId);

    setRequests(updatedRequests);
  };

  const handleConfirmCancellation = async () => {
    console.log("Delete request:", selectedRequestId);
    try {
      const response = await fetch(baseUrl + '/api/status/cancel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestId: selectedRequestId, pwd: selectedPWD }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const result = await response.json();
      if (result.success) {
        deleteRequest(selectedRequestId);
        setKey(prevKey => prevKey + 1);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
    setSelectedRequestId(null);
    setSelectedPWD(null);
    setCancelPopupOpen(false);
  };

  useEffect(() => {
    if (location.state && location.state.requests) {
      setRequests(location.state.requests);
    } else {
      setRequests([]);
    }
  }, [location.state]);

  useEffect(() => {
    requests.forEach(request => {
      if (request.status === 'received') {
        fetchReceivedNumber(request.requestId);
      }
    });
  }, [requests]);

  const fetchReceivedNumber = async (requestId) => {
    try {
      const response = await fetch(baseUrl + '/api/status/checkOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestId }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const result = await response.json();
      setReceivedNumbers(prev => ({ ...prev, [requestId]: result.number + 1 }));
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  const getStatusLabel = (status) => {
    const statusLabels = {
      'waiting': '입고 대기',
      'received': '입고 완료',
      'on Repair': '수리중',
      'complete': '수리 완료'
    };
    return statusLabels[status] || status;
  };

  return (
    <div key={key}>
      <Container maxWidth="sm" sx={{ backgroundColor: '#f9f9f9', height: '100vh', paddingTop: 4 }}>
        <Stack direction="row" spacing={15} alignItems="center">
          <IconButton onClick={handleBack} color="primary.black">
            <ArrowBackIcon />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
            <img src={logo} alt="Time Lord" style={{ height: '80px' }} />
          </Box>
        </Stack>
        {requests.map((request) => (
          <Paper key={request.requestId} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2, marginBottom: 2, backgroundColor: '#fff', boxShadow: 'none', border: '1px solid #e0e0e0' }}>
            <Typography variant="body1" sx={{ flex: 1 }}>{getStatusLabel(request.status)}</Typography>
            <Typography variant="body1" sx={{ flex: 1, textAlign: 'center' }}>{request.model}</Typography>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {request.status === 'received' && (
                <>
                  <Typography variant="body1" sx={{ marginRight: 1 }}>
                    순번
                  </Typography>
                  <Typography variant="body2" sx={{ marginRight: 1 }}>
                    {receivedNumbers[request.requestId]}
                  </Typography>
                </>
              )}
              {request.status === 'waiting' && !request.sentNum && request.serviceType === 'online' ? (
                <>
                  <Button variant="outlined" sx={{ marginRight: 1, borderColor: '#000', color: '#000', backgroundColor: '#f9f9f9' }} onClick={() => handleOpenAddTracking(request.requestId, request.pwd)}>송장등록</Button>
                  <Button variant="outlined" sx={{ borderColor: '#000', color: '#000', backgroundColor: '#f9f9f9' }} onClick={() => handleOpenCancelPopup(request.requestId, request.pwd)}>접수취소</Button>
                </>
              ) : (
                request.status !== 'waiting' && (
                  <IconButton onClick={() => handleOpenChat(request.requestId, request.pwd)}>
                    <ChatIcon />
                  </IconButton>
                )
              )}
            </Box>
          </Paper>
        ))}
        <CancelPopup
          open={isCancelPopupOpen}
          handleClose={handleCloseCancelPopup}
          handleConfirm={handleConfirmCancellation}
        />
        <AddTracking
          open={isAddTrackingOpen}
          handleClose={handleCloseAddTracking}
          handleConfirm={handleConfirmAddTracking}
        />
      </Container>
    </div>
  );
}

export default Status;