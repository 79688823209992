import React from 'react';
import { Box } from '@mui/material';
import logoImage from '../../assets/timeload.png';

const Logo = () => (
  <Box sx={{
    display: 'flex',  // Use flexbox for alignment
    justifyContent: 'center',  // Center horizontally
    alignItems: 'center',  // Center vertically
    width: '100%',  // Full width of the container
    maxWidth: 600,  // Maximum width of the logo
    height: 'auto'  // Automatic height based on content
  }}>
    <img src={logoImage} alt="Time Load Logo" style={{ maxWidth: '100%', height: 'auto' }} />
  </Box>
);

export default Logo;