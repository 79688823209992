// src/pages/selectionPage.js
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Correct import for useNavigate
import Logo from '../components/selection/logo';
import RepairButton from '../components/selection/repairButton';
import CheckButton from '../components/selection/checkButton';
import ContactButton from '../components/selection/contactButton';
import ContactPopup from '../components/selection/contactPopup';
import CallIcon from '@mui/icons-material/Call';

function SelectionPage() {
  const [isContactPopupOpen, setContactPopupOpen] = useState(false);
  const navigate = useNavigate(); // Use useNavigate here for navigation

  const handleOpenContactPopup = () => setContactPopupOpen(true);
  const handleCloseContactPopup = () => setContactPopupOpen(false);

  const handleRepairClick = () => {
    navigate('/agreement'); // Redirect to the submission page
  };

  const handleCheckClick = () => {
    navigate('/login'); // Redirect to the login page
  };

  return (
    <Box sx={{
      textAlign: 'center',
      mt: 4, mb: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f9f9f9'
    }}>
      <Logo />
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '80%',
        maxWidth: 1200,
      }}>
        <RepairButton onClick={handleRepairClick} /> {/* Use onClick to handle navigation */}
        <CheckButton onClick={handleCheckClick} /> {/* Use onClick to handle navigation */}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f9f9f9'
      }}>
        <CallIcon />
        <ContactButton onClick={handleOpenContactPopup} />
      </Box>
      <ContactPopup
        open={isContactPopupOpen}
        onClose={handleCloseContactPopup}
      />
    </Box>
  );
}

export default SelectionPage;