import React from 'react';
import { Button } from '@mui/material';
import checkImage from '../../assets/check.png'; // Import the icon

const checkButton = ({ onClick, sx }) => (
  <Button
    variant="outlined"
    onClick={onClick}
    sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 256,
      height: 256,
      borderColor: '#f9f9f9',
      color: '#000',
      fontSize: '1rem',
      backgroundColor: '#f9f9f9',
      '&:hover .icon': {
        display: 'none' // Hide icon on hover
      },
      '&:hover .text': {
        display: 'flex' // Show text on hover
      },
      '& .text': {
        display: 'none', // By default, text is hidden
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .icon': {
        display: 'flex', // By default, icon is shown
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      ...sx
    }}
  >
    <img src={checkImage} alt="Contact" style={{ width: '70%', height: 'auto', paddingTop: '20px' }} />
  </Button>
);

export default checkButton;