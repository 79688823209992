//addTracking.js
// popup for adding tracking number & company

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const AddTracking = ({ open, handleClose, handleConfirm }) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [company, setCompany] = useState('');

  const companies = [
    '롯데택배',
    '한진택배',
    'CJ대한통운',
    'GS25 편의점',
    'CU 편의점',
    '우체국',
    '로젠택배',
    '그 외'
  ];

  const handleTrackingNumberChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleSubmit = () => {
    console.log('Tracking Number:', trackingNumber);
    console.log('Company:', company);
    handleConfirm(company, trackingNumber)
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>송장 접수</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>운송 회사</InputLabel>
          <Select
            value={company}
            label="Company"
            onChange={handleCompanyChange}
          >
            {companies.map((company, index) => (
              <MenuItem key={index} value={company}>
                {company}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          fullWidth
          label="송장 번호"
          value={trackingNumber}
          onChange={handleTrackingNumberChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button onClick={handleSubmit}>등록</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTracking;