// agreement.js 

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Stack } from '@mui/material';

const AgreementPage = () => {
    const navigate = useNavigate();

    const handleAgree = () => {
        navigate('/submission');
    };

    const handleDisagree = () => {
        navigate('/');
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
                개인정보 수집 및 이용 동의.
            </Typography>
            <Typography variant="body1" paragraph>
                1. 개인정보 수집 항목.
            </Typography>
            <Typography variant="body2" paragraph>
                - 개인 식별 정보: 이름, 전화번호, 주소
            </Typography>
            <Typography variant="body1" paragraph>
                2. 개인정보의 수집 및 이용 목적
            </Typography>
            <Typography variant="body2" paragraph>
                - 제공하신 정보는 Timelord의 수리 접수를 위해 사용됩니다.
            </Typography>
            <Typography variant="body2" paragraph>
                가. 수리 접수자 식별을 위해 활용: 이름, 전화번호
            </Typography>
            <Typography variant="body2" paragraph>
                나. 수리 이후 제품 발송을 위해 활용: 이름, 전화번호, 주소
            </Typography>
            <Typography variant="body1" paragraph>
                3. 개인정보의 보유 및 보유 기간.
            </Typography>
            <Typography variant="body2" paragraph>
                - 수집된 개인 식별 정보 이름, 전화번호, 주소는 수리 접수 일자를 기준으로 1년 동안 보관됩니다. 1년이 경과하면 수집된 개인 정보는 복구가 불가능한 방법으로 즉시 폐기 및 삭제됩니다.
            </Typography>
            <Typography variant="body2" paragraph>
                ※ 귀하는 이에 대한 동의를 거부할 수 있으며, 다만, 동의가 없을 경우 Timelord의 수리 접수가 불가능할 수 있음을 알려드립니다.
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
                <Button variant="contained" color="primary" onClick={handleAgree}>
                    동의합니다.
                </Button>
                <Button variant="outlined" color="primary" onClick={handleDisagree}>
                    동의하지 않습니다.
                </Button>
            </Stack>
        </Box>
    );
};

export default AgreementPage;