import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, IconButton, List, ListItem, Typography, styled, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BaseUrl from '../config';

const MessageItem = styled(ListItem)(({ theme, senderType }) => ({
    justifyContent: senderType === 'user' ? 'flex-end' : 'flex-start',
    padding: theme.spacing(1),
    display: 'flex',
}));

const MessageBubble = styled(Typography)(({ theme, senderType }) => ({
    maxWidth: '80%',
    padding: theme.spacing(1),
    borderRadius: '20px',
    backgroundColor: senderType === 'user' ? '#e0e0e0' : '#3f51b5',
    color: senderType === 'user' ? '#000' : '#fff',
    boxShadow: theme.shadows[1],
    display: 'inline-block',
}));

const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [requestId, setRequestId] = useState();
    const [pwd, setPwd] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (location.state && location.state.requestInfo) {
            setRequestId(location.state.requestInfo.requestId);
            setPwd(location.state.requestInfo.pwd);
        }

        let interval = setInterval(fetchMessages, 15000);  // Refresh every 15 seconds
        fetchMessages();  // Initial fetch
        return () => clearInterval(interval);  // Clear interval on component unmount
    }, [requestId, pwd]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const fetchMessages = async () => {
        if (requestId && pwd) {
            try {
                const response = await fetch(`${BaseUrl}/api/chat/getChat`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ requestId, pwd }),
                });
                const data = await response.json();
                if (data.success) {
                    setMessages(data.chat);
                } else {
                    setMessages([]);
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        }
    };

    const handleSendMessage = async () => {
        if (newMessage.trim()) {
            try {
                const response = await fetch(`${BaseUrl}/api/chat/sendChat`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ requestId, pwd, message: newMessage }),
                });
                const data = await response.json();
                if (data.success) {
                    setNewMessage('');
                    fetchMessages();  // Refresh messages after sending
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" spacing={2} alignItems="left" sx={{ p: 2 }}>
                <IconButton onClick={handleBack} color="primary">
                    <ArrowBackIcon />
                </IconButton>
            </Stack>
            <List sx={{ overflowY: 'auto', flexGrow: 1 }}>
                {messages.slice().map((msg, index) => (
                    <MessageItem key={index} senderType={msg.senderType}>
                        <MessageBubble senderType={msg.senderType} variant="body1">
                            {msg.message}
                        </MessageBubble>
                    </MessageItem>
                ))}
                <div ref={messagesEndRef} />
            </List>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Type your message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                />
                <IconButton onClick={handleSendMessage} color="primary" sx={{
                    width: 56,
                    height: 56,
                    borderRadius: '50%',
                }}>
                    <SendIcon />
                </IconButton>
            </Stack>
        </Box>
    );
};

export default ChatPage;