import {React, useState, useEffect} from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, Box } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import baseUrl from '../../config';

function WarningPopup({ open, onClose, onConfirm }) {
  const [rank, setRank] = useState(0);
  
  const getRank = async () => {
    try {
      const response = await fetch(baseUrl + '/api/status/checkOrder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({requestId : null}),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const result = await response.json();
      if(result.number){
        setRank(result.number);
      }

    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  useEffect(() => {
    if (open) {
      getRank();
    }
  }, [open, rank]); 

  return (
    <Dialog open={open} onClose={onClose} sx={{
      '& .MuiDialog-paper': {
        width: '100%',  // Adjust width according to the device width or design requirement
        maxWidth: '400px',  // Ensures the popup is not too wide on larger screens
        borderRadius: '10px'  // Rounded corners for the dialog
      }
    }}>
      <Box sx={{
        textAlign: 'center',  // Centers everything inside the box
        padding: 3,  // Provides padding around the content
        bgcolor: 'background.paper'  // Ensures background color matches the theme
      }}>
        <WarningAmberIcon sx={{ fontSize: 40, color: 'warning.main', mt: 2 }} />
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mt: 2 }}>
          주의!!
        </Typography>
        <DialogContent>
          <DialogContentText sx={{ mt: 2, mb: 4 }}>
            입고 후 순차적으로 수리가 진행됩니다, 대기 번호가 많이 밀려있을 경우 수리가 지연 될 수 있습니다.
          </DialogContentText>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
            {rank + 1} 번
          </Typography>
          <DialogContentText sx={{ fontSize: '1rem', mt: 2 }}>
            (내 예상 대기번호)
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button onClick={onClose} variant="outlined" sx={{ mx: 1 }}>
            취소
          </Button>
          <Button onClick={onConfirm} variant="contained" color="primary" sx={{ mx: 1 }}>
            접수
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default WarningPopup;