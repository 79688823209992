// src/components/selection/contactButton.js
import React from 'react';
import { Button } from '@mui/material';

const ContactButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      color: 'black',
      backgroundColor: '#f9f9f9',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#e0e0e0',
        textDecoration: 'underline'
      }
    }}
  >
    Contact
  </Button>
);

export default ContactButton;