import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';
import logo from '../assets/timeload.png'; // Ensure the path to your image is correct

const ConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs" sx={{
      textAlign: 'center',
      mt: 8,
      bgcolor: '#f9f9f9', // Background color for the container
      padding: 3, // Add some padding
      boxShadow: 1, // Add a subtle shadow (optional)
    }}>
      <img src={logo} alt="Time Lord Logo" style={{ width: '100%', marginBottom: 20, border: '1px solid black' }} />
      <Typography variant="h5" gutterBottom sx={{ color: 'black' }}>
        접수가 완료되었습니다.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'black', marginBottom: 2 }}>
        택배 발송 후, “접수내역 확인”에서 송장 등록을 해주세요. 송장 미 등록시 반송 및 지연이 될 수 있습니다.
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate('/login')}
        sx={{
          mt: 3,
          mb: 2,
          bgcolor: 'black', // Button color
          color: 'white', // Text color
          '&:hover': {
            bgcolor: 'darkgrey' // Color when the button is hovered over
          },
          border: '1px solid black' // Border for the button
        }}
      >
        접수내역 확인
      </Button>
    </Container>
  );
};

export default ConfirmationPage;