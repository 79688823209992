//cancelPopup.js

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

function CancelPopup({ open, handleClose, handleConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"접수 취소"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          접수를 취소하시겠습니까? 취소 후에는 복구할 수 없습니다.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          네
        </Button>
        <Button onClick={handleClose} color="primary">
          아니오
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelPopup;