import React, { useState } from 'react';
import { TextField, Button, Alert, Box, Container, createTheme, ThemeProvider, IconButton, Stack } from '@mui/material';
import logo from '../assets/timeload.png';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import baseUrl from '../config';

const theme = createTheme({
    palette: {
        primary: {
            main: '#F9F9F9',
        },
        background: {
            default: '#F9F9F9',
        },
        text: {
            primary: '#000000',
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused': {
                        color: '#000000',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#000000',
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#000000',
                        },
                        '&:hover fieldset': {
                            borderColor: '#000000',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#000000',
                        },
                    }
                }
            }
        }
    }
});

function LoginPage() {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        name: '',
        phone: '',
        pwd: ''
    });
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (prop) => (event) => {
        let value = event.target.value;

        // If the input field is 'phone', replace all non-digit characters
        if (prop === 'phone' || prop === 'pwd') {
            value = value.replace(/\D/g, ''); // Remove non-digit characters
        }

        setForm({ ...form, [prop]: value });

        // Reset error when user starts typing
        if (error) setError(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { name, phone, pwd } = form;

        if (!name || !phone || !pwd) {
            setError(true);
            setErrorMessage('모든 내용을 기입하여 주십시오.');
            return;
        }

        try {
            const response = await fetch(baseUrl + '/api/login/check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            });

            if (response.status === 401) {
                throw new Error('조회된 정보가 없습니다.');
            }

            const data = await response.json();
            console.log(data);
            navigate('/status', { state: { requests: data.requests } }); // Pass the requests data to /status route
        } catch (err) {
            setError(true);
            setErrorMessage(err.message || 'An unexpected error occurred');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm" style={{ backgroundColor: '#F9F9F9', color: '#000000', height: '100vh' }}>
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box onClick={() => navigate('/')} sx={{ width: '100%', height: 150, overflow: 'hidden', display: 'flex', justifyContent: 'center', mb: 4 }}>
                        <img src={logo} alt="Company Logo" style={{ width: 'auto', height: '100%' }} />
                    </Box>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            required
                            error={error && !form.name}
                            label="이름"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={form.name}
                            onChange={handleChange('name')}
                        />
                        <TextField
                            required
                            error={error && !form.phone}
                            label="전화번호"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={form.phone}
                            onChange={handleChange('phone')}
                        />
                        <TextField
                            required
                            error={error && !form.pwd}
                            label="비밀번호"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={form.pwd}
                            onChange={handleChange('pwd')}
                        />
                        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3, mb: 2 }}>
                            접수 내역 조회
                        </Button>
                        {error && <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default LoginPage;