import React, { useState } from 'react';
import {
  Box, TextField, Button, FormControl, FormControlLabel, Radio, RadioGroup, FormLabel,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/timeload.png';
import WarningPopup from '../components/submission/warningPopup';
import baseUrl from '../config';

function SubmissionPage() {
  const [formData, setFormData] = useState({
    name: '',
    pwd: '',
    phone: '',
    address: '',
    model: '',
    serviceType: 'online',
    details: ''
  });
  const [fieldErrors, setFieldErrors] = useState({
    name: false,
    pwd: false,
    phone: false,
    address: false,
    model: false,
    details: false
  });
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    let modifiedValue = value;

    // Check if the input field is 'phone' and allow only digits
    if (name === 'phone' || name === 'pwd') {
      modifiedValue = value.replace(/\D/g, ''); // Remove all non-digit characters
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: modifiedValue
    }));

    setFieldErrors(prev => ({ ...prev, [name]: false })); // Clear error on change
  };

  const validateForm = () => {
    const errors = {
      name: !formData.name,
      pwd: !formData.pwd,
      phone: !formData.phone,
      model: !formData.model,
      details: !formData.details,
      address: formData.serviceType === 'online' && !formData.address
    };
    setFieldErrors(errors);
    return !Object.values(errors).some(Boolean); // Return false if any errors are true
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setOpenPopup(true); // Open popup if form is valid
    }
  };

  const handlePopupConfirm = async () => {
    setOpenPopup(false);
    try {
      const response = await fetch(baseUrl + '/api/confirm/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 401) {
        throw new Error('No user found with the provided credentials.');
      }

      const data = await response.json();
      if (data.success) {
        navigate('/confirmation'); // Navigate after confirming on popup
      }
    } catch (err) {
      console.error('Failed to submit:', err);
    }
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#F9F9F9' }}>
      <Box component="form" onSubmit={handleSubmit} sx={{
        position: 'relative',
        width: '90%',
        maxWidth: '600px',
        p: 4,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: '#F9F9F9'
      }}>
        <Box onClick={() => navigate('/')} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', height: '150px', mb: 4 }}>
          <img src={logo} alt="Company Logo" style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
        </Box>
        <FormControl component="fieldset" margin="normal" fullWidth>
          <FormLabel component="legend">접수 방식</FormLabel>
          <RadioGroup row name="serviceType" value={formData.serviceType} onChange={handleChange}>
            <FormControlLabel value="online" control={<Radio />} label="택배 접수" />
            <FormControlLabel value="offline" control={<Radio />} label="현장 접수" />
          </RadioGroup>
        </FormControl>
        <Box display="flex" justifyContent="space-between">
          <TextField
            id="outlined-name"
            label="이름"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={fieldErrors.name}
            helperText={fieldErrors.name && 'This field is required'}
            variant="outlined"
            margin="normal"
            sx={{ width: '48%' }}
          />
          <TextField
            id="outlined-password"
            label="접수 비밀번호"
            name="pwd"
            type="pwd"
            value={formData.pwd}
            onChange={handleChange}
            error={fieldErrors.pwd}
            helperText={fieldErrors.pwd && 'This field is required'}
            variant="outlined"
            margin="normal"
            sx={{ width: '48%' }}
          />
        </Box>
        <TextField
          id="outlined-phone"
          label="연락처"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          error={fieldErrors.phone}
          helperText={fieldErrors.phone && 'This field is required'}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          id="outlined-address"
          label="주소"
          name="address"
          value={formData.address}
          onChange={handleChange}
          error={fieldErrors.address && formData.serviceType === 'online'}
          helperText={fieldErrors.address && formData.serviceType === 'online' && 'This field is required'}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          id="outlined-model"
          label="모델명"
          name="model"
          value={formData.model}
          onChange={handleChange}
          error={fieldErrors.model}
          helperText={fieldErrors.model && 'This field is required'}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          id="outlined-details"
          label="수리 내용을 간략히 적어주세요"
          name="details"
          value={formData.details}
          onChange={handleChange}
          error={fieldErrors.details}
          helperText={fieldErrors.details && 'This field is required'}
          variant="outlined"
          fullWidth
          multiline
          rows={6}
          margin="normal"
        />
        <Button type="submit" variant="contained" sx={{
          mt: 3,
          mb: 2,
          width: '100%',
          bgcolor: 'black',
          color: 'white',
          '&:hover': {
            bgcolor: 'darkgrey'
          },
          border: '1px solid black'
        }}>수리 접수</Button>
      </Box>
      <WarningPopup open={openPopup} onClose={handlePopupClose} onConfirm={handlePopupConfirm} />
    </Box>
  );
}

export default SubmissionPage;