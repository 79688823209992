// src/components/selection/contactPopup.js
import React from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import contactImage from '../../assets/contact.png';

const ContactPopup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <img src={contactImage} alt="Contact" style={{ width: '100%', height: 'auto' }} />
    </Dialog>
  );
};

export default ContactPopup;